<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('ComponentComments')"
      :root="$t('Comments')"
      :subtitle="componentName"
      :root-location="businessDashLink('courses/' + this.uuid + '/comments')"
      icon="mdi-forum"
      fill-height
      width="760px"
  >
    <div :class="[{'px-2' : SM  }]"   >

      <v-sheet class="wsRoundedLight py-3 px-6 my-5" :color="wsLIGHTCARD" :style="`border : 1px solid ${wsBACKGROUND}`">

        <div class="justify-space-between align-center"
             :class="[{'d-flex' : !SM}]"
        >
          <h4 >{{ componentName }}</h4>
          <div                 v-if="hasFilters" class="d-flex justify-space-between align-center">
            <h5 class="mr-3"> {{ $t('DisplayAll') }} </h5>
            <ws-switch

                v-model="displayAll"
            />
          </div>
        </div>

        <v-expand-transition>
          <v-simple-table
              v-if="hasFilters && !displayAll && !SM"
              class="pt-3"
              style="background-color: transparent"
          >
            <tbody style="background-color: transparent">
            <tr  v-if="wavesSelect.length > 0">
              <td>
                <h5 :style="`color : ${wsACCENT}`" class="mr-3" > {{ $t('EnrollmentWave') }}: </h5>
              </td>
              <td width="10px">
                <ft-select :items="wavesSelect" v-model="filters.schedule_id">
                  <template v-slot="{text}">
                    <h5 class="text-no-wrap">{{ text }} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
                  </template>
                </ft-select>
              </td>
            </tr>
            <tr v-if="groupsSelectFiltered.length > 0">
              <td>
                <h5 :style="`color : ${wsACCENT}`" class="mr-3"> {{ $t('Group') }}: </h5>
              </td>
              <td width="10px">
                <ft-select :items="groupsSelectFiltered" v-model="filters.group_id">
                  <template v-slot="{text}">
                    <h5 class="text-no-wrap">{{ text }} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
                  </template>
                </ft-select>
              </td>
            </tr>
            <tr v-if="plansSelect.length > 0" >
              <td>
                <h5 :style="`color : ${wsACCENT}`" class="mr-3"> {{ $t('PaymentPlan') }}: </h5>
              </td>
              <td width="10px">
                <ft-select :items="plansSelect" v-model="filters.payment_plan_id">
                  <template v-slot="{text}">
                    <h5 class="text-no-wrap" >{{ text }} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
                  </template>
                </ft-select>
              </td>
            </tr>
            </tbody>

          </v-simple-table>
          <div v-else-if="hasFilters && !displayAll && SM">
            <div  v-if="wavesSelect.length > 0">
              <h5 :style="`color : ${wsACCENT}`" class="mr-3" > {{ $t('EnrollmentWave') }}: </h5>
              <ft-select :items="wavesSelect" v-model="filters.schedule_id">
                <template v-slot="{text}">
                  <h5 class="text-no-wrap">{{ text }} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
                </template>
              </ft-select>
            </div>
            <div class="mt-3" v-if="groupsSelectFiltered.length > 0">
              <h5 :style="`color : ${wsACCENT}`" class="mr-3"> {{ $t('Group') }}: </h5>
              <ft-select :items="groupsSelectFiltered" v-model="filters.group_id">
                <template v-slot="{text}">
                  <h5 class="text-no-wrap">{{ text }} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
                </template>
              </ft-select>
            </div>
            <div class="mt-3" v-if="plansSelect.length > 0">
              <h5 :style="`color : ${wsACCENT}`" class="mr-3"> {{ $t('PaymentPlan') }}: </h5>
              <ft-select :items="plansSelect" v-model="filters.payment_plan_id">
                <template v-slot="{text}">
                  <h5 class="text-no-wrap" >{{ text }} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
                </template>
              </ft-select>
            </div>

          </div>


        </v-expand-transition>

      </v-sheet>

      <v-expand-transition>
        <v-sheet v-if="!displayAll" :style="`border: 1px solid ${wsBACKGROUND}`" class="wsRoundedLight mb-10">
          <ws-text-field
              v-model="entityData.text"
              solo :outlined="false" flat area>
          </ws-text-field>
          <div class="d-flex justify-end pr-3 pb-3 ">
            <v-btn @click="sendComment" :color="wsATTENTION" class="noCaps" dark> {{ $t('Write') }}</v-btn>
          </div>
        </v-sheet>
      </v-expand-transition>


      <component-comment
          v-for="(comment , i) in rootComments" :key="i"
          @reply="items.unshift($event)"
          @delete="deleteComponent(comment, $event)"
          :comment="comment"
          :children="getChildComments(comment.uuid)"
          :waves="wavesSelect"
          :plans="plansSelect"
          :groups="groupsSelect"
          :displayFilter="displayAll"
          admin
      />
    </div>
  </sub-page>

</template>

<script>
import {mapActions, mapMutations} from "vuex";
import componentComment from "@/components/pages/westudy/student/StudentCourse/UI/componentComment";

export default {
  name: "courseComments",
  components : {
    componentComment
  },
  props : {
    componentId : {
      type : String
    },
    uuid : {
      type : String
    }
  },
  data() {
    return {
      displayAll : true,
      items : [],
      entityData : {},
      groupsSelect : [],
      wavesSelect : [],
      plansSelect : [],
      filters : {},
      componentName : ''
    }
  },
  computed : {
    groupsSelectFiltered() {
      if (this.groupsSelect.length === 0) {
        return []
      }

      let items = this.groupsSelect

      if (this.wavesSelect.length > 0 ) {
        items = items.filter( el => el.enrollment_wave === this.filters.schedule_id )
      } else {
        items = items.filter( el => el.enrollment_wave === null )
      }

      return items

    },
    wavesSelectFiltered() {
      if (this.wavesSelect.length === 0) {
        return []
      }

      let items = this.wavesSelect

      let newItems = []
      items.forEach( item => {
        newItems.push({
          text : item.text + ` (${this.getCommentsFieldCount('schedule_id' , item.value)})`,
          value : item.value
        })
      })

      return newItems

    },
    plansSelectFiltered() {
      if (this.plansSelect.length === 0) {
        return []
      }

      let items = this.plansSelect

      let newItems = []
      items.forEach( item => {
        newItems.push({
          text : item.text + ` (${this.getCommentsFieldCount('payment_plan_id' , item.value)})`,
          value : item.value
        })
      })

      return newItems

    },
    rootComments() {

      if ( this.items.length === 0 ) {
        return
      }

      let items = this.items.filter(el => !el.parent)

      if ( !this.displayAll ) {
        if ( this.wavesSelect.length > 0 ) {
          items = items.filter(el => el.schedule_id === this.filters.schedule_id )
        }
        if ( this.plansSelect.length > 0 ) {
          items = items.filter(el => el.payment_plan_id === this.filters.payment_plan_id )
        }
        if ( this.groupsSelectFiltered.length > 0 ) {
          items = items.filter(el => el.group_id === this.filters.group_id )
        }
      }

      return items
    },
    hasFilters() {
      return this.groupsSelectFiltered.length > 0 || this.wavesSelect.length > 0 || this.plansSelect.length > 0
    }
  },
  watch : {
    course(val) {
      this.entityData = val
      this.INIT_PAGE({
        icon : 'mdi-forum',
        title : this.$t('ComponentComments'),
        root : this.$t('Comments'),
        rootLocation: this.businessDashLink('courses/' + this.uuid + '/comments'),
        subtitle : this.componentName,
      })
    },
  },
  methods : {
    ...mapActions('courses',['GET_COURSE_COMPONENT_COMMENTS_ADMIN' , 'SEND_COMPONENT_COMMENT_ADMIN']),
    ...mapMutations('courses' , ['INIT_PAGE']),

    getCommentsFieldCount(field , value) {
      if ( this.items.length === 0 ) {
        return 0
      }
      let items = this.items.filter( el => el[field] === value )
      return items.length
    },


    getChildComments(commentId) {
      if ( this.items.length === 0 ) {
        return
      }
      return this.items.filter(el => el.parent === commentId)
    },
    deleteComponent(component , child ) {
      let index = this.items.findIndex(el => el.uuid === (child ? child.uuid : component.uuid)  )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index, 1)
      this.notify(this.$t('CommentDeleted'))
    },

    async sendComment() {
      this.entityData.component_id = this.componentId

      if ( this.filters.schedule_id) {
        this.entityData.schedule_id = this.filters.schedule_id
      }
      if ( this.filters.group_id) {
        this.entityData.group_id = this.filters.group_id
      }
      if ( this.filters.payment_plan_id) {
        this.entityData.payment_plan_id = this.filters.payment_plan_id
      }

      let result = await this.SEND_COMPONENT_COMMENT_ADMIN(this.entityData)
      if ( !result ) {
        return
      }
      this.entityData.text = ''
      this.items.unshift(result)
      this.notify(this.$t('CommentSent'))
    },
    async initPage() {

      let result = await this.GET_COURSE_COMPONENT_COMMENTS_ADMIN(this.componentId)
      if ( !result || result === true ) {
        return
      }
      this.items = result.comments
      this.wavesSelect = result.enrollment_waves
      this.plansSelect = result.payment_plans
      this.groupsSelect = result.groups

      if (result.payment_plans.length > 0 ) {
        this.filters.payment_plan_id = result.comments[0].payment_plan_id ? result.comments[0].payment_plan_id : result.payment_plans[0].value
      }
      if (result.enrollment_waves.length > 0 ) {
        this.filters.schedule_id = result.comments[0].schedule_id ? result.comments[0].schedule_id : result.enrollment_waves[0].value
      }
      if (this.groupsSelectFiltered.length > 0 ) {
        this.filters.group_id = result.comments[0].group_id ? result.comments[0].group_id : this.groupsSelectFiltered[0].value
      }
      this.filters = Object.assign({} , this.filters)
      this.componentName = result.component_name

      this.INIT_PAGE({
        icon : 'mdi-forum',
        title : this.$t('ComponentComments'),
        root : this.$t('Comments'),
        rootLocation: this.businessDashLink('courses/' + this.uuid + '/comments'),
        subtitle : this.componentName,
      })

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>